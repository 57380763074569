import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  min-height: 630px;
  background-color: ${grayscale[100]};
  display: flex;
  align-items: center;

  @media(max-width: ${breakpoints.md}) {
    min-height: 530px;
  }

  .social-media-card {
    min-height: 150px;
    padding: 20px;
    background-color: ${white};
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .p-200 {
    width: 200px;

    @media(max-width: ${breakpoints.sm}) {
      width: 100%;
    }
  }

  .p-250 {
    width: 250px;

    @media(max-width: ${breakpoints.sm}) {
      width: 100%;
    }
  }

  .link {
    margin-left: auto;
  }

  .subtitle {
    font-family: "Sora", Helvetica, sans-serif !important;
  }

  .title {
    text-align: start;
    @media(min-width: ${breakpoints.sm}) {
      text-align: center;
    }
    @media(min-width: ${breakpoints.xl}) {
      text-align: start;
    }

  }

`
