import { grayscale, orange } from 'src/styles/colors';
import styled from 'styled-components';

type CardProp = {
  expanded: boolean;
}

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  background-color: ${grayscale[500]};

  .react-multi-carousel-track {
     padding: 0 0 8px 0;
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        background-color: #FF7A00;
      }
    }
  }

  .react-multiple-carousel__arrow--right {
    svg{
      fill: #FF7A00;
    }
  }

  .react-multiple-carousel__arrow--left {
    svg{
      fill: #FF7A00;
    }
  }
`

export const Card = styled.div<CardProp>`
  background-color: white;
  margin: 30px 12px;
  width: 90%;
  border-radius: 12px;
  min-height: 860px;
  position: relative;
  padding-bottom: 30px;
  height: ${({ expanded }: CardProp) => expanded ? 'fit-content' : '770px'};

  .spacing-evenly { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
  }
`

export const Logo = styled.div`
  height: 120px;
  background-color: ${grayscale[100]};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const ExpandButton = styled.div`
  position: absolute;
  bottom: 0;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 100%;
  color: ${orange.extra};
  text-decoration: underline;
  background-color: white;
  height: 50px;
  cursor: pointer;
  padding-left: 15px;
  padding-top: 10px;
  font-weight: 600;
`
